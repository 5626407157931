<div app-looma-page [pageTitle]="pageTitle" style="position: relative; height: 100%;">

    <div fxLayout="row" fxLayoutGap="16" fxLayoutAlign="stretch" style="width: 100%; height:100%">
        <div style="position:relative;" fxFlex>
            <div
                style="position: absolute; left:0; top:0; width: 100%; height: 100%; overflow: scroll;  background: white">

                <table cellpadding="0" cellspacing="0" class="mat-table">
                    <thead>
                    <tr *ngFor="let row of table.headerRows" class="mat-row mat-header-row">
                        <td class="header-cell" *ngFor="let cell of row.cells" [colSpan]="cell.config.colSpan"
                            [rowSpan]="cell.config.rowSpan" [class.sticky-cell]="cell.config.sticky">
                            <div class="cell_content">{{ cell.config.text }}</div>
                        </td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let row of table.bodyRows; let even = even;" class="mat-row">
                        <td class="mat-cell" *ngFor="let cell of row.cells"
                            [colSpan]="cell.config.colSpan"
                            [rowSpan]="cell.config.rowSpan"
                            [class.disabled-slot]="cell.isDisabled"
                            [class.sticky-cell]="cell.config.sticky"

                            [style.background]="cell.config.color">
                            <div class="cell_content">
                                <div *ngIf="!cell.isBodyCell">{{ cell.config.text }}</div>

                                <ng-container *ngIf="cell.isBodyCell ">

                                    <ng-container *ngIf="cell.isActionCell">
                                        <button mat-icon-button style="width: 100%" (click)="insertCell(cell)"
                                                color="warn">
                                            <mat-icon>add</mat-icon>
                                            Empty Slot
                                        </button>
                                    </ng-container>

                                    <ng-container *ngIf="cell.isBrandAssignableCell">
                                        <div class="editable-slot-cell"
                                             style="width: 100%; line-height: 38px;"
                                             [style.background-color]="getBackgroundColor(cell)"
                                             [class.empty-cell]="cell.isEmptySlot"
                                             [class.assigned-slot-cell]="!cell.isEmptySlot"
                                             [class.selected-cell]="cell.isSelected"
                                             [class.disabled-cell]="!cellSelection.isItemSelectable(cell)">
                                            <mat-checkbox [disabled]="!cellSelection.isItemSelectable(cell)"
                                                          [(ngModel)]="cell.isSelected">
                                                <span class="text ellipsize">{{ cell.brandSlot.displayText }}</span>
                                            </mat-checkbox>
                                            <span *ngIf="cell.hasDirtyContent" class="dirty-mark"></span>
                                        </div>
                                    </ng-container>

                                </ng-container>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>

            </div>

        </div>
        <div style=" display: flex; z-index: 1" fxFlex="400px" class="mat-elevation-z3"
             *ngIf="brandCampaignsPanelVisible">
            <looma-grid [dataSource]="brandCampaignsDataSource">
                <ng-template emptyDataContent>
                    <div style="text-align: center; padding:16px">No Campaigns</div>
                </ng-template>

                <ng-template gridColumnHeader="name">
                    <span style="color: white; font-size:14px">Brand Campaigns</span>
                </ng-template>

                <ng-template gridColumn="name" let-brandCampaign="data">
                    <span class="bullet" [style.background-color]="getBackgroundColor(brandCampaign)"></span>
                    <span>{{ brandCampaign.name }}</span>
                </ng-template>

                <ng-template gridColumnHeader="row_actions">
                    <button mat-icon-button (click)="brandCampaignsPanelVisible=false">
                        <mat-icon style="color: white">close</mat-icon>
                    </button>
                </ng-template>

                <ng-template rowActions="2" let-brandCampaign="data">
                    <button mat-icon-button (click)="editBrandCampaign(brandCampaign)" title="Edit">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <mat-menu #brandCampaignMenu="matMenu">
                        <button mat-menu-item (click)="editBrandCampaignSlots(brandCampaign)">Edit Slots</button>
                        <button mat-menu-item (click)="deleteBrandCampaign(brandCampaign)">Delete</button>
                    </mat-menu>

                    <button mat-icon-button [matMenuTriggerFor]="brandCampaignMenu"
                            *ngIf="canEditBrandCampaignSlots(brandCampaign)">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                </ng-template>
            </looma-grid>
        </div>
    </div>

    <ng-template pageHeaderNav>
        <ng-container *ngIf="editingSlotsForCampaign">
            <span class="mr-16">{{ editingSlotsForCampaign.name }}</span>
            <button mat-raised-button color="warn" (click)="applySlotSelectionToCampaign(editingSlotsForCampaign)"
                    [disabled]="!cellSelection.hasSelectedItems">Save Selection
            </button>
            <button mat-button (click)="applySlotSelectionToCampaign(null)">Cancel</button>
        </ng-container>
        <ng-container *ngIf="!editingSlotsForCampaign">

            <button mat-raised-button color="warn" *ngIf="canGenerateCampaigns()"
                    (click)="generateCampaigns()">Generate Campaigns
            </button>

            <button mat-raised-button color="warn" [disabled]="!cellSelection.canAssignBrandCampaign"
                    (click)="createNewBrandCampaign()">New Brand Campaign
            </button>

            <button mat-icon-button [matMenuTriggerFor]="optionsMenu">
                <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #optionsMenu="matMenu">
                <button mat-menu-item (click)="brandCampaignsPanelVisible=true" [disabled]="brandCampaignsPanelVisible">
                    Show Brand Campaigns
                </button>
                <button mat-menu-item (click)="cellSelection.clear()" [disabled]="!cellSelection.hasSelectedItems">Clear
                    Selection
                </button>
            </mat-menu>
        </ng-container>
    </ng-template>
</div>

